.header {
    flex-grow: 0 !important;
}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.icon {
    font-size: 3em !important;
    margin-right: 15px !important;
}

.text {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
}

.text .title {
    font-size: 18px;
    font-weight: bold;
    margin: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text .subtitle {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}