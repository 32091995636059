#centeredPdfModal {
    /* Fixes an issue in Semantic UI 2.4 where the fullscreen modal are stuck to the left edge of the screen */
    left: auto !important;
}

.pdfModal {
    height: 90%;
}

.pdfModal .content {
    max-height: 100% !important;
    height: 100%;
    padding: 0 !important;
    background: #999999 !important;
}

.pdfModal :global(.header) {
    padding: .5rem !important;
}

.pdfModal :global(.close.icon) {
    top: .7rem !important;
}