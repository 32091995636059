.coin {
    border-radius: 100%;
    background-color: rgb(118, 118, 118);
    width: 28px;
    height: 28px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.label {
    line-height: 28px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    color: white;
}