#centeredModal {
    /* Fixes an issue in Semantic UI 2.4 where the fullscreen modal are stuck to the left edge of the screen */
    left: auto !important;
}

.modal {
    height: 90%;
}

.modal .content {
    height: inherit;
}

.modal object {
    width: 100%;
    height: 100%;
}