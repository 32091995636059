body {
    overscroll-behavior: none;
}

.ui.cards > .yellow.color-top.card, 
.ui.yellow.color-top.card, 
.ui.yellow.color-top.cards > .card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #fbbd08,0 1px 3px 0 #d4d4d5;
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #fbbd08,0 1px 3px 0 #d4d4d5;
}

.ui.cards > .yellow.color-top.raised.card,
.ui.yellow.color-top.raised.card,
.ui.yellow.color-top.cards > .raised.card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #fbbd08,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #fbbd08,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}

.ui.cards > .orange.color-top.card, 
.ui.orange.color-top.card, 
.ui.orange.color-top.cards > .card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #f2711c,0 1px 3px 0 #d4d4d5;
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #f2711c,0 1px 3px 0 #d4d4d5;
}

.ui.cards > .orange.color-top.raised.card,
.ui.orange.color-top.raised.card,
.ui.orange.color-top.cards > .raised.card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #f2711c,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #f2711c,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}

.ui.cards > .green.color-top.card, 
.ui.green.color-top.card, 
.ui.green.color-top.cards > .card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #21ba45,0 1px 3px 0 #d4d4d5;
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #21ba45,0 1px 3px 0 #d4d4d5;
}

.ui.cards > .green.color-top.raised.card,
.ui.green.color-top.raised.card,
.ui.green.color-top.cards > .raised.card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #21ba45,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #21ba45,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}

.ui.cards > .teal.color-top.card, 
.ui.teal.color-top.card, 
.ui.teal.color-top.cards > .card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #00b5ad,0 1px 3px 0 #d4d4d5;
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #00b5ad,0 1px 3px 0 #d4d4d5;
}

.ui.cards > .teal.color-top.raised.card,
.ui.teal.color-top.raised.card,
.ui.teal.color-top.cards > .raised.card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #00b5ad,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #00b5ad,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}

.ui.cards > .blue.color-top.card, 
.ui.blue.color-top.card, 
.ui.blue.color-top.cards > .card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #2185d0,0 1px 3px 0 #d4d4d5;
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #2185d0,0 1px 3px 0 #d4d4d5;
}

.ui.cards > .blue.color-top.raised.card,
.ui.blue.color-top.raised.card,
.ui.blue.color-top.cards > .raised.card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #2185d0,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #2185d0,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}

.ui.cards > .purple.color-top.card, 
.ui.purple.color-top.card, 
.ui.purple.color-top.cards > .card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #a333c8,0 1px 3px 0 #d4d4d5;
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #a333c8,0 1px 3px 0 #d4d4d5;
}

.ui.cards > .purple.color-top.raised.card,
.ui.purple.color-top.raised.card,
.ui.purple.color-top.cards > .raised.card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #a333c8,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #a333c8,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}

.ui.cards > .pink.color-top.card, 
.ui.pink.color-top.card, 
.ui.pink.color-top.cards > .card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #e03997,0 1px 3px 0 #d4d4d5;
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #e03997,0 1px 3px 0 #d4d4d5;
}

.ui.cards > .pink.color-top.raised.card,
.ui.pink.color-top.raised.card,
.ui.pink.color-top.cards > .raised.card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #e03997,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #e03997,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}

.ui.cards > .red.color-top.card, 
.ui.red.color-top.card, 
.ui.red.color-top.cards > .card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #db2828,0 1px 3px 0 #d4d4d5;
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #db2828,0 1px 3px 0 #d4d4d5;
}

.ui.cards > .red.color-top.raised.card,
.ui.red.color-top.raised.card,
.ui.red.color-top.cards > .raised.card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #db2828,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #db2828,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}

.ui.cards > .black.color-top.card, 
.ui.black.color-top.card, 
.ui.black.color-top.cards > .card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #1b1c1d,0 1px 3px 0 #d4d4d5;
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #1b1c1d,0 1px 3px 0 #d4d4d5;
}

.ui.cards > .black.color-top.raised.card,
.ui.black.color-top.raised.card,
.ui.black.color-top.cards > .raised.card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #1b1c1d,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #1b1c1d,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}

.ui.cards > .grey.color-top.card, 
.ui.grey.color-top.card, 
.ui.grey.color-top.cards > .card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #767676,0 1px 3px 0 #d4d4d5;
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #767676,0 1px 3px 0 #d4d4d5;
}

.ui.cards > .grey.color-top.raised.card,
.ui.grey.color-top.raised.card,
.ui.grey.color-top.cards > .raised.card {
    -webkit-box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #767676,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
    box-shadow: 0 0 0 1px #d4d4d5,0 -2px 0 0 #767676,0 1px 3px 0 #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.input.bold > input,
.field.bold > .input > input {
    font-weight: bold;
}