.workstation:global(.ui.card) > :global(.content > .header) {
    min-height: 45px;
}

.workstation.inactive:global(.ui.card) :global(.ui.statistic > .value),
.workstation.inactive:global(.ui.card) > :global(.content > .header > .header) {
    color: rgba(0,0,0,.4);
}

.workstation.inactive:global(.ui.card) > :global(.content > .header > .header) {
    color: rgba(0,0,0,.4);
}