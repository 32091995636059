.toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.toolbar button {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}