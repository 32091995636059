.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title {
    display: block;
    line-height: 1em;
}

.subtitle {
    font-size: small;
}