.mainTopBar {
  height: 53px;
  z-index: 103 !important;

  /* Override unwanted Semantic UI properties */
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.wideTopBarButton {
  padding: 13px 25px !important;
}

#toggleNavigationMenu:global(.disabled) {
  pointer-events: none;
  cursor: pointer;
  color: rgba(0, 0, 0, .87) !important;
}

.pageTitle::before {
  display: none;
}