.container {
    min-height: 100%;
    background-color: #999999;
}

.page {
    margin-bottom: 1rem;
}

:global(.react-pdf__Page__canvas) {
    margin: 0 auto;
}