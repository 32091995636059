body {
  background-color: #f2f4f5;
}

/* resolve some conflicts between Semantic UI and Tachyons */
.ui.fixed.table{
  position: inherit;
}

.app {
  height: 100%;
}