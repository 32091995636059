.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.workstation {
    margin: 10px !important;
}

.placeholder {
    width: 290px;
    border: 1px dashed grey;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}