.pageContainer {
  height: 100%;
}

.pageContainer :global(.pushable) {
  /* Fixes the offset issue with the drag and drop */
  -webkit-transform: none !important;
  transform: none !important;
}

.pageContent {
  padding-top: 60px;
  margin: 0 8px;
}