.toastNotification {
    margin: 5px 0 0 0 !important;
    min-height: 65px !important;
}

.complexIconContainer {
    margin-right: 24px;
}

.information {
    -webkit-box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent !important;
    box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent !important;
    background-color: #f8ffff !important;
    color: #276f86 !important;
}

.success {
    -webkit-box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent !important;
    box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent !important;
    background-color: #fcfff5 !important;
    color: #2c662d !important;
}

.warning {
    -webkit-box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent !important;
    box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent !important;
    background-color: #fffaf3 !important;
    color: #573a08 !important;
}

.error {
    -webkit-box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent !important;
    box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent !important;
    background: #fff6f6 !important;
    color: #9f3a38 !important;
}