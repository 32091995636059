.systemStatusIcon {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    color: rgba(0, 0, 0, .87);
    border-radius: 4px;
}

.bordered {
    padding: 14px;
    width: 65px;
    height: 65px;

    -webkit-box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset;
    box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset;
    background-color: #f8f8f9;
}

.information {
    -webkit-box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
    background-color: #f8ffff;
    color: #276f86;
}

.success {
    -webkit-box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent;
    background-color: #fcfff5;
    color: #2c662d;
}

.warning {
    -webkit-box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
    background-color: #fffaf3;
    color: #573a08;
}

.error {
    -webkit-box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
    background: #fff6f6;
    color: #9f3a38;
}